import { ref, watch } from '@vue/composition-api'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'

export default function useGroupMembers() {
  // Use toast
  const toast = useToast()

  const refReportsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'Report Name', sortable: false },
    { key: 'reportView', label: 'Report View', sortable: false },
    { key: 'created_by', label: 'Created By', sortable: false },
    { key: 'created_at', label: 'Created At', sortable: false },
    { key: 'filtered_by_program', label: 'Filtered By Program', sortable: false },
    { key: 'actions' },
  ]
  const perPage = ref(DEFAULT_PER_PAGE)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = PER_PAGE_OPTIONS
  const searchQuery = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = ref({
    from: 0,
    to: 0,
    of: 0,
  })

  const refetchData = () => {
    refReportsListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchReportsList = (ctx, callback) => {
    const payloadData = {
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
    }

    if (searchQuery.value) {
      payloadData.name = searchQuery.value
    }

    store
      .dispatch('app-reports/fetchReportsList', payloadData)
      .then(response => {
        const { data } = response.data
        const { total, from, to } = response.data.meta
        callback(data)
        totalItems.value = total
        dataMeta.value = {
          from,
          to,
          of: total,
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching reports list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteReport = ID => axios
    .delete(`auth/reports/${ID}`)
    .then(() => {
      refetchData()
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success deleting report',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error deleting report',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const cloneReport = ID => axios
    .get(`auth/reports/${ID}/clone`)
    .then(() => {
      refetchData()
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success cloning report',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error cloning report',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const exportReport = queryParams => axios
    .post('auth/reports/export', queryParams)
    .then(response => response)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error exporting report',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const exportStudentsApplications = () => axios
    .get('/auth/reports/hardcoded/csv/application-students-or-campers')
    .then(response => response)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error exporting report',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const exportParentsTransactions = () => axios
    .get('/auth/reports/hardcoded/csv/parents-transactions')
    .then(response => response)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error exporting report',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const actionOptions = [
    { value: 'edit', title: 'Edit', link: 'admin-report-details' },
  ]

  const resolveStatusVariant = status => {
    if (status === true) return 'success'
    return 'primary'
  }

  return {
    fetchReportsList,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    totalItems,
    isSortDirDesc,
    refReportsListTable,

    statusFilter,
    actionOptions,

    deleteReport,
    cloneReport,
    exportReport,
    exportStudentsApplications,
    exportParentsTransactions,

    refetchData,

    resolveStatusVariant,
  }
}
