<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="table-header m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mr-1 mb-0">Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              :reduce="option => option.value"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <label class="mr-1 mb-0">Search</label>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                class="w-25"
              />
              <b-button
                v-if="canCreateReport"
                class="ml-1"
                variant="primary"
                :to="{ name: 'admin-report-details' }"
              >
                <span class="mr-25 align-middle text-light">Create Report</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refReportsListTable"
        :items="fetchReportsList"
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Loader -->
        <template #table-busy>
          <TableSpinner />
        </template>

        <!-- Column: name -->
        <template #cell(name)="data">
          <b-link
            :to="{ name: 'admin-report-details', query: { id: data.item.id } }"
          >{{ data.item.name }}</b-link>
        </template>

        <!-- Column: report type -->
        <template
          #cell(reportView)="data"
        >
          <span v-if="data.item.reportView">{{ data.item.reportView.name }}</span>
        </template>

        <!-- Column: created by -->
        <template #cell(created_by)="data">
          <span v-if="data.item.user_id">{{ data.item.user_id.full_name }}</span>
        </template>

        <!-- Column: created at -->
        <template #cell(created_at)="data">
          {{ data.item.created_at | longDateTime }}
        </template>

        <!-- Column: filtered_by_program -->
        <template #cell(filtered_by_program)="data">
          <b-badge
            :variant="`light-${resolveStatusVariant(data.item.reportView.with_program_filter)}`"
          >
            {{ data.item.reportView.with_program_filter }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="mx-auto p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-for="option in actionOptions"
              :key="option.value"
              :to="{ name: option.link, query: { id: data.item.id } }"
            >
              <span>{{ option.title }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="canDeleteReport"
              @click="deleteReport(data.item.id)"
            >
              <span>Delete</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="canExportCSV"
              v-b-modal.export-report
              @click="exportReportCSV(data.item)"
            >
              <span>Export</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="totalItems && perPage !== 'all'"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

          <!-- Export Report -->
          <b-modal
            id="export-report"
            ref="export-report"
            ok-title="Save"
            :ok-disabled="!csvFile"
            cancel-variant="outline-secondary"
            centered
            title="Export Report"
            no-close-on-backdrop
            @close="resetFile"
            @ok="saveCSV"
          >
            <div
              v-if="isLoading"
              class="text-center"
            >
              <b-spinner
                small
              />
            </div>
            <span v-else>{{ csvName }}.csv</span>
          </b-modal>

        </b-row>
      </div>

    </b-card>

    <!-- <b-row>
      <b-col
        cols="12"
      >
        <b-button
          class="ml-1 mb-1"
          variant="primary"
          :disabled="isStudentsExporting"
          @click="exportStudentsApplicationsToCsv"
        >
          <b-spinner
            v-if="isStudentsExporting"
            small
          />
          <span class="mr-25 align-middle text-light">Export {{ camperStudent(2) }}+Applications to CSV</span>
        </b-button>
      </b-col>
      <b-col
        cols="12"
      >
        <b-button
          class="ml-1 mb-1"
          variant="primary"
          :disabled="isParentsExporting"
          @click="exportParentsTransactionsToCsv"
        >
          <b-spinner
            v-if="isParentsExporting"
            small
          />
          <span class="mr-25 align-middle text-light">Export Parents+Transactions to CSV</span>
        </b-button>
      </b-col>
    </b-row> -->
  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination, VBModal,
  BDropdown, BDropdownItem, BButton, BLink, BSpinner, BBadge,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { downloadExportFile } from '@core/mixins/downloadExportFile'
import { filters } from '@core/mixins/filters'
import moment from 'moment'

import vSelect from 'vue-select'
import store from '@/store'
import storeModule from '@/views/admin/reports-v2/reportStoreModule'
import useReportsList from '@/views/admin/reports-v2/reports/reports-list/useReportsList'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  name: 'ReportsList',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BButton,
    BLink,
    BSpinner,
    BBadge,

    vSelect,

    TableSpinner,
  },
  directives: {
    'b-modal': VBModal,
  },
  filters: {
    formatDate(value) {
      return value ? moment(value).format('YYYY-MM-DD') : ''
    },
  },
  mixins: [downloadExportFile, filters],
  data() {
    return {
      isLoading: false,
      isStudentsExporting: false,
      isParentsExporting: false,
      selectedReportName: null,
      csvFile: null,
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-reports'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchReportsList,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      totalItems,
      isSortDirDesc,
      refReportsListTable,

      statusFilter,
      actionOptions,

      cloneReport,
      deleteReport,
      exportReport,
      exportStudentsApplications,
      exportParentsTransactions,

      refetchData,

      resolveStatusVariant,
    } = useReportsList()

    return {
      fetchReportsList,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      totalItems,
      isSortDirDesc,
      refReportsListTable,

      statusFilter,
      actionOptions,

      cloneReport,
      deleteReport,
      exportReport,
      exportStudentsApplications,
      exportParentsTransactions,

      refetchData,

      resolveStatusVariant,
    }
  },
  computed: {
    csvName() {
      return `${this.selectedReportName}-report-${moment().format('YYYY-MM-DD')}`
    },
    canCreateReport() {
      return this.$can('create', permissionSubjects.Report)
    },
    canDeleteReport() {
      return this.$can('delete', permissionSubjects.Report)
    },
    canExportCSV() {
      return this.$can('export', permissionSubjects.ExportCSV)
    },
    programId() {
      return store.getters['verticalMenu/getDefaultProgram']
    },
  },
  methods: {
    resetFile() {
      this.csvFile = null
    },
    async exportReportCSV(report) {
      this.isLoading = true
      this.selectedReportName = report.name

      const queryParams = {
        report_view_id: report.reportView.id,
        selected_fields: report.selected_fields,
        filters: report.filters,
      }

      if (report.reportView.with_program_filter) {
        queryParams.program_id = this.programId
      }

      await this.exportReport(queryParams)
        .then(response => {
          this.isLoading = false
          this.csvFile = response.data
        })
    },
    saveCSV() {
      this.downloadFile(this.csvFile, this.csvName, 'csv')
    },
    async exportStudentsApplicationsToCsv() {
      this.isStudentsExporting = true
      await this.exportStudentsApplications().then(response => {
        this.downloadFile(response.data, `students+applications-${moment().format('YYYY-MM-DD')}`, 'csv')
        this.isStudentsExporting = false
      })
    },
    async exportParentsTransactionsToCsv() {
      this.isParentsExporting = true
      await this.exportParentsTransactions().then(response => {
        this.downloadFile(response.data, `parents+transactions-${moment().format('YYYY-MM-DD')}`, 'csv')
        this.isParentsExporting = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.table-header {

  label {
    margin-bottom: 0;
  }
}

.badge {
  text-transform: uppercase;
}

.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
